<template>
  <div
    class="auth-wrapper auth-v1"
  >
    <template>
      <div
        v-if="!isLoading"
        class="auth-inner"
      >
        <v-card class="auth-card">
          <v-card-title class="d-flex align-center justify-center">
            <router-link
              to="/"
              class="d-flex align-center"
            >
              <v-img
                :src="`${url_public}${config.isotipo ? config.isotipo : config.logo}`"
                max-height="100px"
                max-width="100px"
                alt="logo"
                contain
                class="me-3 "
              ></v-img>

              <h2 class="text-2xl font-weight-semibold">
                {{ config.name }}
              </h2>
            </router-link>
          </v-card-title>

          <v-card-text>
            <p class="font-weight-semibold text--primary mb-2">
              {{ $t('landing.downloadCatalog') }} <br />
            </p>
          </v-card-text>
        </v-card>
      </div>

      <WstIzq
        v-if="name_project === 'wwwww'"
        class="auth-tree"
        width="230"
        height="200"
        :color="$vuetify.theme.themes.light.primary"
      />
      <WstDerecha
        v-if="name_project === 'wwwww'"
        class="auth-tree-3"
        width="250"
        height="150"
        :color="$vuetify.theme.themes.light.primary"
      />
    </template>

    <Loading
      v-if="isLoading"
      :full-page="true"
      :color="$vuetify.theme.themes.light.primary"
    />
  </div>
</template>

<script>
import { mdiClose } from '@mdi/js'
// eslint-disable-next-line import/no-unresolved
import WstIzq from '@/views/utils/images/wst_izq.vue'
// eslint-disable-next-line import/no-unresolved
import WstDerecha from '@/views/utils/images/wst_derecha.vue'
// eslint-disable-next-line no-unused-vars, import/no-unresolved
import Loading from '@/views/utils/loading/bars.vue'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    Loading,
    WstIzq,
    WstDerecha,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    id: {
      type: String,
    },
  },
  data() {
    return {
      urlS3: process.env.VUE_APP_API_URL_S3_HOTEL,
      config: {
        logo: null,
        name: '',
      },
      event: null,
      isLoading: true,
      url_public: process.env.VUE_APP_API_URL_PLUBLIC,
      name_project: process.env.VUE_APP_PROJ_NAME,
      icons: {
        mdiClose,
      },
    }
  },
  created() {
    if (localStorage.getItem('configData')) {
      this.config = JSON.parse(localStorage.getItem('configData'))
    }
    this.fetchDataFromEndpoints()
  },
  methods: {
    async fetchDataFromEndpoints() {
      try {
        const jsonLoad = {
          id: this.id,
        }
        const [
          resLoad,
        ] = await Promise.all([
          this.axios
            .post('catalogs/qr-download-event', jsonLoad),
        ])

        if (resLoad.data.success) {
          this.event = resLoad.data.data.name

          // const url = this.urlS3 + resLoad.data.data.url
          this.downloadAPK(resLoad.data.data.url)
        } else {
          this.$router.push({ name: 'error-404' })
        }
      } catch (err) {
        console.error('Error fetching data:', err)
      }
    },
    async downloadAPK(fileUrl) {
      try {
        /* const link = document.createElement('a')
        link.href = fileUrl
        link.setAttribute('download', `${this.catalog}.pdf`)
        document.body.appendChild(link)

        await link.click()
        document.body.removeChild(link)
        link.remove() */

        // Realiza la solicitud para obtener el PDF
        const response = await fetch(fileUrl)

        // Verifica si la respuesta es correcta
        if (!response.ok) {
          throw new Error('Error al descargar el PDF')
        }

        // Crea un Blob a partir de la respuesta
        const blob = await response.blob()

        // Crea una URL para el Blob
        const url = window.URL.createObjectURL(blob)

        // Crea un elemento <a> y simula un clic en él
        const a = document.createElement('a')
        a.href = url
        a.download = `${this.event}.pdf`
        document.body.appendChild(a)
        a.click()

        // Limpia
        a.remove()
        window.URL.revokeObjectURL(url)

        this.isLoading = false
      } catch (error) {
        console.error('Error al descargar el archivo:', error)
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>
<style lang="scss">
@import '@core/preset/preset/pages/auth.scss';
</style>
